.el-message-box__wrapper .el-message-box {
  display: block;
  margin: 0 auto ;
  margin-top: 15.625vw;
  width: 20.3125vw;
  width: 10.520833333333334vw;
}
.partner-wrapper {
  padding-top: 4.6875vw;
}
.partner-wrapper .banner-container {
  box-sizing: border-box;
  height: 35.41666666666667vw;
  padding-top: 10.989583333333334vw;
  background-color: #00092a;
  background-image: url(https://mktv-in-cdn.mockuai.com/16270289621293325.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.partner-wrapper .banner-container .info {
  margin-left: 12.5vw;
}
.partner-wrapper .banner-container .info h1.title {
  margin-bottom: 1.1458333333333333vw;
  line-height: 1.4;
  font-size: 3.125vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.partner-wrapper .banner-container .info .desc {
  margin-bottom: 3.125vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.partner-wrapper .banner-container .info .btn {
  width: 17.708333333333336vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  cursor: pointer;
}
.partner-wrapper .banner-container .info .btn:hover {
  background-color: #ff6600;
  color: #fff;
}
.partner-wrapper .service-container {
  box-sizing: border-box;
  height: 50.78125vw;
  padding-top: 4.791666666666667vw;
  background-color: #fff;
}
.partner-wrapper .service-container > .title {
  margin-bottom: 3.802083333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.partner-wrapper .service-container .cards {
  margin: 0 auto;
  width: 75vw;
}
.partner-wrapper .service-container .cards .cards-row {
  display: flex;
  align-items: center;
}
.partner-wrapper .service-container .cards .cards-row .card-item {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 17.1875vw;
  height: 12.5vw;
  margin-right: 2.083333333333333vw;
  margin-bottom: 2.083333333333333vw;
  border-radius: 0.8333333333333334vw;
  background-color: #00092a;
  background-size: cover;
  background-repeat: no-repeat;
}
.partner-wrapper .service-container .cards .cards-row .card-item:last-child {
  margin-right: 0;
}
.partner-wrapper .service-container .cards .cards-row .card-item .text {
  font-size: 0.9375vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #ffffff;
}
.partner-wrapper .service-container .btn {
  margin: 2.8125vw auto 0;
  width: 17.708333333333336vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #ff6600;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.partner-wrapper .service-container .btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.partner-wrapper .market-container {
  box-sizing: border-box;
  height: 63.541666666666664vw;
  padding-top: 5vw;
  background: url(https://mktv-in-cdn.mockuai.com/16270318139755493.png) left top / cover no-repeat, linear-gradient(133deg, #00092a 0%, #001554 100%);
}
.partner-wrapper .market-container > .title {
  margin-bottom: 2.083333333333333vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.partner-wrapper .market-container .content {
  position: relative;
  margin: 0 auto 4.16666667vw;
  width: 75vw;
  height: 41.354166666666664vw;
}
.partner-wrapper .market-container .content .main {
  width: 100%;
}
.partner-wrapper .market-container .btn {
  margin: 0 auto;
  width: 17.708333333333336vw;
  height: 3.125vw;
  line-height: 3.125vw;
  text-align: center;
  border-radius: 1.5625vw;
  background-color: #fff;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff6600;
  cursor: pointer;
}
.partner-wrapper .market-container .btn:hover {
  font-weight: 600;
}
.partner-wrapper .award-container {
  box-sizing: border-box;
  height: 15.156249999999998vw;
  padding-top: 4.895833333333333vw;
  background-color: #fff;
  background-image: url(https://mktv-in-cdn.mockuai.com/16270322350365195.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.partner-wrapper .award-container > .title {
  margin-bottom: 0.78125vw;
  text-align: center;
  line-height: 2.604166666666667vw;
  font-size: 1.875vw;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.partner-wrapper .award-container .text {
  margin-bottom: 3.125vw;
  text-align: center;
  line-height: 5.833333333333333vw;
  font-size: 4.166666666666666vw;
  font-family: PingFangSC-Light, PingFang SC;
  font-weight: 300;
  color: #333333;
}
.partner-wrapper .award-container .text .num {
  color: #ff6600;
}
.partner-wrapper .award-container .btn {
  margin: 0 auto;
  width: 17.708333333333336vw;
  height: 3.125vw;
  line-height: 3.125vw;
  border-radius: 1.5625vw;
  background-color: #ff6600;
  text-align: center;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  cursor: pointer;
}
.partner-wrapper .award-container .btn:hover {
  font-weight: 600;
  box-shadow: 0px 7px 21px 0px rgba(255, 102, 0, 0.4);
}
.partner-wrapper .qrcode-modal {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
}
.partner-wrapper .qrcode-modal .qrcode-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-wrapper .qrcode-modal .qrcode-box .text {
  margin-bottom: 1.3020833333333335vw;
  line-height: 1.7187500000000002vw;
  font-size: 1.25vw;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
}
.partner-wrapper .qrcode-modal .qrcode-box .qrcode {
  width: 13.541666666666666vw;
  height: 13.541666666666666vw;
  margin-bottom: 3.0208333333333335vw;
}
.partner-wrapper .qrcode-modal .qrcode-box .close-icon {
  width: 2.083333333333333vw;
  height: 2.083333333333333vw;
  cursor: pointer;
}
